import * as React from 'react';
import { IStackTokens, Stack } from 'office-ui-fabric-react/lib/Stack';
import { Dropdown, IDropdownStyles, IDropdownOption } from 'office-ui-fabric-react/lib/Dropdown';

export interface LanguageSelectorProps{
    selectLang: any,
    defaultKey: any
}

const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: 100, float: 'right', marginTop: 10 },
};

const options: IDropdownOption[] = [
  { key: 'de', text: 'Deutsch', data: { icon: 'Memo'} },
  { key: 'fr', text: 'Français', data: { icon: 'Print'} },
  { key: 'en', text: 'English', data: { icon: 'Print'} },
];

  const onRenderOption = (option: IDropdownOption): JSX.Element => {
    return (
      <div>
        <span>{option.text}</span>
      </div>
    );
  };

const stackTokens: IStackTokens = { childrenGap: 20 };

export const LanguageSelector: React.FunctionComponent<LanguageSelectorProps> = ({selectLang, defaultKey}) => {
  return (
    <Stack tokens={stackTokens}>
      <Dropdown
        placeholder="Deutsch"
        options={options}
        styles={dropdownStyles}
        onRenderOption={onRenderOption}
        onChange={selectLang}
        defaultSelectedKey={defaultKey}
      />
    </Stack>
  );
};